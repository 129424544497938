.cancel-account-subscription {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 350px; }

.cancel-subscription-modal .cancel-subscription-modal__title {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .cancel-subscription-modal .cancel-subscription-modal__title .warning-icon-wrapper {
    font-size: 3rem;
    line-height: 2rem; }

.cancel-subscription-modal .cancel-subscription-modal__footer {
  display: flex;
  justify-content: space-between; }
  .cancel-subscription-modal .cancel-subscription-modal__footer .cancel-subscription-modal__footer-actions {
    display: flex; }

.subscription-expiration-date {
  display: flex;
  gap: 5px; }

.subscription-expiration-date-msg .subscription-expiration-date-msg__support {
  display: flex;
  justify-content: center; }

.user-data-licenses {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 350px; }

.licenses-available-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .licenses-available-wrapper .licenses-available__title {
    text-align: center; }
    .licenses-available-wrapper .licenses-available__title h4 {
      margin-bottom: 0; }
  .licenses-available-wrapper .licenses-available {
    display: inline-grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
    .licenses-available-wrapper .licenses-available .licenses-available__icon {
      line-height: 0;
      font-size: 1.5rem;
      cursor: pointer; }
    .licenses-available-wrapper .licenses-available .licenses-available__value {
      margin: 0 1rem;
      font-size: 2rem;
      text-align: center;
      color: var(--primary-color);
      max-width: 50px; }

.add-licenses {
  width: 100%; }

.add-licenses,
.add-licenses-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap; }
  .add-licenses .form,
  .add-licenses-content .form {
    width: 100%; }
  .add-licenses .add-license-card-payment-wrapper .add-license-card-payment__title,
  .add-licenses-content .add-license-card-payment-wrapper .add-license-card-payment__title {
    font-size: 1rem;
    margin-bottom: 0.5rem; }
  .add-licenses .add-license-card-payment-wrapper .add-license-card-payment,
  .add-licenses-content .add-license-card-payment-wrapper .add-license-card-payment {
    padding: 6px 4px 6px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s; }
  .add-licenses .licence-product-option,
  .add-licenses-content .licence-product-option {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
    .add-licenses .licence-product-option .licence-product-option__price,
    .add-licenses-content .licence-product-option .licence-product-option__price {
      color: var(--dark-medium-color); }
  .add-licenses .add-license-total-price,
  .add-licenses-content .add-license-total-price {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 18px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
  .add-licenses .licenses-quantity,
  .add-licenses-content .licenses-quantity {
    margin-bottom: 0; }

.account-page-sections-wrapper .ant-card {
  border-radius: 0.3rem;
  margin: 1rem;
  padding: 1rem;
  max-width: 600px; }

.account-page-sections-wrapper .ant-card-head-title {
  padding-top: 0; }

.account-page .user-data-form,
.account-page .user-data-password-form,
.account-page .user-notifications-form,
.account-page .user-billing-data {
  min-width: 250px;
  max-width: 500px; }

.account-page .account-page-sections-wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; }
  .account-page .account-page-sections-wrapper .account-section {
    flex: 1 1 auto; }

.account-page .card .ant-card-body {
  padding: 0 24px 24px 24px; }

.account-page .ant-form-item {
  margin-bottom: 12px; }

.account-page .account-billing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem; }

.arrivals {
  overflow: auto; }
  .arrivals .arrivals-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .arrivals .arrivals-header .arrivals-reload-scoring {
      display: flex;
      align-items: center;
      gap: 0.5rem; }
  .arrivals .arrivals-table .reservationId,
  .arrivals .arrivals-table .scoring {
    width: 75px !important;
    text-align: center; }
  .arrivals .arrivals-table .delete {
    width: 25px !important;
    text-align: center;
    background-color: #fff !important;
    border-color: transparent !important; }
  .arrivals .arrivals-table .inDate,
  .arrivals .arrivals-table .outDate,
  .arrivals .arrivals-table .reservationDate {
    width: 100px !important; }
  .arrivals .arrivals-table .guestName {
    width: 150px !important; }
  .arrivals .arrivals-table .table-row.row-not-valid,
  .arrivals .arrivals-table .table-row .cell-not-valid {
    background-color: var(--invalid-color); }

.create-account-page {
  padding: 1rem;
  max-width: 850px;
  margin: 0 auto;
  background: #fff; }
  .create-account-page .create-account .create-account-form-wrapper .create-account-form {
    min-width: 280px; }
    .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-form-data-section {
      width: 100%;
      display: flex;
      gap: 1.5rem; }
      .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-form-data-section .create-account-form-data-section__admin {
        width: 100%; }
      .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-form-data-section .create-account-form-data-section__business {
        width: 100%; }
    .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-form-item .create-account-form-item__input input:placeholder-shown {
      text-transform: capitalize; }
    .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-form-item__legal {
      overflow-x: auto;
      flex-basis: 100%;
      background: var(--bg-color);
      padding: 1rem;
      margin: 1rem 0;
      border-radius: 4px;
      max-height: 50vh; }
    .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-checkbox-group {
      width: 100%;
      display: flex;
      justify-content: center; }
      .create-account-page .create-account .create-account-form-wrapper .create-account-form .create-account-checkbox-group .checkbox {
        text-transform: capitalize; }
  .create-account-page .create-account .create-account-success .create-account-success__action-wrapper {
    margin-top: 1rem; }

@media only screen and (max-width: 400px) {
  .create-account-page .create-account .create-account-form .create-account-checkbox-group {
    flex-direction: column; } }

.how-it-works-trigger {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  text-transform: capitalize; }
  .how-it-works-trigger .how-it-works-text {
    display: none; }

.how-it-works-modal .ant-modal-content .ant-modal-body {
  padding: 0;
  display: flex; }

@media only screen and (min-width: 600px) {
  .how-it-works-trigger .how-it-works-text {
    display: block; } }

.establishments-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 1rem; }

.page-login {
  height: 100vh;
  width: 100%; }
  .page-login .login-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'login-form login-company-display'; }
    .page-login .login-wrapper .login-form-wrapper {
      background: var(--secondary-color);
      grid-area: login-form;
      display: flex;
      align-items: center;
      justify-content: center; }
      .page-login .login-wrapper .login-form-wrapper .login-form {
        width: 350px; }
        .page-login .login-wrapper .login-form-wrapper .login-form .password-wrapper {
          margin-bottom: 0; }
        .page-login .login-wrapper .login-form-wrapper .login-form .password-recovery-wrapper {
          text-align: right;
          margin-bottom: 1rem;
          color: var(--primary-color);
          cursor: pointer; }
        .page-login .login-wrapper .login-form-wrapper .login-form .loginBtn-wrapper {
          display: flex;
          justify-content: center; }
        .page-login .login-wrapper .login-form-wrapper .login-form .go-back {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 5px;
          left: 10px;
          transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
          cursor: pointer; }
          .page-login .login-wrapper .login-form-wrapper .login-form .go-back span {
            display: inline-block; }
            .page-login .login-wrapper .login-form-wrapper .login-form .go-back span::first-letter {
              text-transform: uppercase; }
          .page-login .login-wrapper .login-form-wrapper .login-form .go-back:hover {
            color: var(--primary-color); }
    .page-login .login-wrapper .login-company-display-wrapper {
      grid-area: login-company-display; }
      .page-login .login-wrapper .login-company-display-wrapper .login-company-display {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .top-asset,
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .bottom-asset,
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .company-logo {
          user-select: none; }
          .page-login .login-wrapper .login-company-display-wrapper .login-company-display .top-asset img,
          .page-login .login-wrapper .login-company-display-wrapper .login-company-display .bottom-asset img,
          .page-login .login-wrapper .login-company-display-wrapper .login-company-display .company-logo img {
            width: 100%;
            height: auto; }
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .top-asset {
          position: absolute;
          top: 0;
          right: 0; }
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .company-logo {
          width: 100%;
          max-width: 500px;
          margin: 0 7rem;
          z-index: 1; }
        .page-login .login-wrapper .login-company-display-wrapper .login-company-display .bottom-asset {
          position: absolute;
          bottom: 0;
          left: -2px;
          max-width: 600px;
          min-width: 200px;
          width: 100%; }
          .page-login .login-wrapper .login-company-display-wrapper .login-company-display .bottom-asset img {
            vertical-align: bottom; }

@media only screen and (max-width: 780px) {
  .page-login {
    overflow: hidden; }
    .page-login .login-wrapper {
      height: 100vh;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 'login-company-display' 'login-form'; }
      .page-login .login-wrapper .login-form-wrapper .login-form {
        width: 60vw;
        min-width: 200px; }
      .page-login .login-wrapper .login-form-wrapper .login-form-wrapper-card {
        position: absolute;
        top: 30%;
        z-index: 2; }
      .page-login .login-wrapper .login-company-display-wrapper .login-company-display .company-logo {
        max-width: 250px;
        min-width: 100px;
        margin: 0 auto;
        position: absolute;
        top: 20%; }
      .page-login .login-wrapper .login-company-display-wrapper .login-company-display .top-asset {
        max-width: 80px; }
      .page-login .login-wrapper .login-company-display-wrapper .login-company-display .bottom-asset {
        max-width: none;
        width: 150%;
        bottom: -2px;
        left: -4px; } }

@media only screen and (max-width: 600px) {
  .page-login .login-wrapper .login-form-wrapper .login-form {
    width: 80vw;
    min-width: 200px; } }

.mappings-page .mappings-page-content {
  max-width: 1200px; }
  .mappings-page .mappings-page-content .mapping-page-section {
    height: 450px;
    width: 100%; }
    .mappings-page .mappings-page-content .mapping-page-section .section {
      height: 100%; }
      .mappings-page .mappings-page-content .mapping-page-section .section .np-list {
        border: 1px solid #f0f0f0; }
        .mappings-page .mappings-page-content .mapping-page-section .section .np-list .np-list-wrapper .np-list-data {
          margin-bottom: 0; }
  .mappings-page .mappings-page-content .mappings-page-establishments-wrapper .mappings-filters .filters .filter {
    width: 100%; }
  .mappings-page .mappings-page-content .mapping-page-sections-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    width: 100%; }
  .mappings-page .mappings-page-content .np-list .np-list-data .np-list-item-content-row .np-list-item.content-item.month div::first-letter {
    text-transform: uppercase; }

.menu {
  padding-top: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto; }
  .menu .menu-item {
    display: flex;
    align-items: center; }
    .menu .menu-item .anticon {
      line-height: 0;
      display: flex;
      align-items: center; }
  .menu .menu-logo {
    max-width: 150px;
    padding: 8px;
    margin: 0 auto 48px auto; }
    .menu .menu-logo.collapsed {
      max-width: 55px; }
    .menu .menu-logo .logo {
      width: 100%;
      height: auto; }

.reporting {
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  flex-direction: column; }
  .reporting > .section {
    display: grid; }
    .reporting > .section .reporting-section-item-page-content {
      width: 100%;
      height: 100%; }
      .reporting > .section .reporting-section-item-page-content .card {
        height: 250px; }
        .reporting > .section .reporting-section-item-page-content .card .ant-card-head {
          height: auto;
          font-size: 1rem; }
        .reporting > .section .reporting-section-item-page-content .card .ant-card-body {
          height: 186px;
          padding: 0; }
    .reporting > .section.section-1 {
      grid-template-columns: 100%;
      grid-template-rows: 1fr; }
      .reporting > .section.section-1 .section-item.deep-and-company-relation {
        width: 100%; }
        .reporting > .section.section-1 .section-item.deep-and-company-relation .deep-and-company-relation__title-wrapper {
          display: flex;
          flex-wrap: wrap; }
          .reporting > .section.section-1 .section-item.deep-and-company-relation .deep-and-company-relation__title-wrapper .deep-and-company-relation__title {
            margin-right: 0.5rem; }
          .reporting > .section.section-1 .section-item.deep-and-company-relation .deep-and-company-relation__title-wrapper .deep-and-company-relation__subtitle {
            color: var(--dark-light-color);
            font-weight: normal; }
      .reporting > .section.section-1 .section-item.sales-and-success-by-seller {
        width: 100%; }
    .reporting > .section.section-2 {
      grid-template-columns: 100%;
      grid-template-rows: 1fr;
      grid-template-areas: 'sub-section' 'metrics' 'scoring-list'; }
      .reporting > .section.section-2 .section-item.scoring-list {
        width: 100%;
        min-width: 300px;
        grid-area: scoring-list; }
      .reporting > .section.section-2 .section-item.metrics {
        grid-area: metrics; }
      .reporting > .section.section-2 .sub-section {
        grid-area: sub-section;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: 'total-sales-vs-potencial' 'hotel-vs-sector-effectiveness' 'upsell-bookings-vs-sector' 'sales-list'; }
        .reporting > .section.section-2 .sub-section .section-item.upselling-cross-selling-chart {
          grid-area: upselling-cross-selling-chart; }
        .reporting > .section.section-2 .sub-section .section-item.total-sales-vs-potencial {
          grid-area: total-sales-vs-potencial; }
        .reporting > .section.section-2 .sub-section .section-item.sales-list {
          grid-area: sales-list; }
        .reporting > .section.section-2 .sub-section .section-item.hotel-vs-sector-effectiveness {
          grid-area: hotel-vs-sector-effectiveness; }
        .reporting > .section.section-2 .sub-section .section-item.upsell-bookings-vs-sector {
          grid-area: upsell-bookings-vs-sector; }
        .reporting > .section.section-2 .sub-section .section-item.upselling-cross-selling-chart {
          display: flex;
          justify-content: center; }
        .reporting > .section.section-2 .sub-section .section-item .recharts-pie-label-text {
          font-family: var(--default-font-family); }
        .reporting > .section.section-2 .sub-section .section-item.total-sales-vs-potencial {
          display: flex;
          justify-content: center; }
          .reporting > .section.section-2 .sub-section .section-item.total-sales-vs-potencial .total-sales-vs-potencial__total {
            text-align: center;
            margin-top: -60px;
            font-family: var(--default-font-family); }
            .reporting > .section.section-2 .sub-section .section-item.total-sales-vs-potencial .total-sales-vs-potencial__total .total-sales-vs-potencial__total-label {
              line-height: 0.8rem; }
            .reporting > .section.section-2 .sub-section .section-item.total-sales-vs-potencial .total-sales-vs-potencial__total .total-sales-vs-potencial__total-value {
              font-size: 1.1rem; }
        .reporting > .section.section-2 .sub-section .section-item.sales-list .reporting-section-item-page-content .card .ant-card-body {
          height: 100%; }
        .reporting > .section.section-2 .sub-section .section-item.sales-list .np-list {
          height: 100%; }
          .reporting > .section.section-2 .sub-section .section-item.sales-list .np-list .np-list-wrapper .np-list-data {
            margin-bottom: 0; }
            .reporting > .section.section-2 .sub-section .section-item.sales-list .np-list .np-list-wrapper .np-list-data .ReactVirtualized__Grid__innerScrollContainer .np-list-virtualized__item:first-child .np-list-item-content-row {
              text-transform: uppercase;
              font-weight: bold;
              color: var(--primary-color); }
    .reporting > .section .section-item {
      padding: 0.4rem; }
  .reporting .reporting-metrics {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
    .reporting .reporting-metrics .reporting-metrics-item {
      padding: 0.2rem;
      text-align: center;
      max-width: 200px; }
      .reporting .reporting-metrics .reporting-metrics-item .reporting-metrics--item__value {
        font-size: 3rem;
        font-family: var(--default-font-family);
        color: var(--primary-color); }
      .reporting .reporting-metrics .reporting-metrics-item .reporting-metrics--item__label {
        font-size: 1rem;
        text-transform: capitalize; }
  .reporting .chart-text {
    font-family: var(--default-font-family);
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap; }
    .reporting .chart-text .chart-text__value {
      font-size: 2.5rem;
      width: 100%;
      color: var(--primary-color); }
    .reporting .chart-text .chart-text__legend {
      width: 100%;
      font-size: 1rem; }
  .reporting .np-list .np-list-item.content-item, .reporting .np-list .np-list-item.header-item {
    padding: 0.2rem !important; }
  .reporting .reporting-scoring-list .potencial-sales-render {
    width: 100%;
    display: grid;
    grid-template-columns: auto 10px; }
    .reporting .reporting-scoring-list .potencial-sales-render .value {
      text-align: right;
      margin-right: 5px; }
    .reporting .reporting-scoring-list .potencial-sales-render .highlight {
      align-self: center;
      display: block;
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
      border-radius: 50%; }

@media only screen and (min-width: 1200px) {
  .reporting {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column; }
    .reporting > .section .reporting-section-item-page-content .card {
      height: 100%; }
    .reporting > .section.section-2 .sub-section {
      grid-template-columns: 50% 25% 25%;
      grid-template-rows: auto auto;
      grid-template-areas: 'sales-list total-sales-vs-potencial total-sales-vs-potencial' 'sales-list hotel-vs-sector-effectiveness upsell-bookings-vs-sector'; } }

.delete-action {
  color: var(--dark-medium-color); }
  .delete-action:hover {
    color: var(--dark-color); }

.badge-wrapper {
  position: relative; }
  .badge-wrapper .badge {
    position: absolute;
    top: -2px;
    right: -10px; }
    .badge-wrapper .badge.small .ant-badge-status-dot {
      width: 6px;
      height: 6px; }
    .badge-wrapper .badge.default .ant-badge-status-dot {
      width: 8px;
      height: 8px; }

.ant-badge-status-processing {
  background-color: var(--primary-color); }
  .ant-badge-status-processing::after {
    border: 1px solid var(--primary-color); }

.button.ant-btn-loading {
  display: flex; }

.button.support-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  gap: 5px;
  line-height: 0;
  padding-top: 5px;
  max-width: 150px; }

.card.hide-head-border-bottom > .ant-card-head {
  border-bottom: none; }

.card.ant-card-bordered .ant-card-head-title {
  padding: 0;
  white-space: normal; }

.card .ant-card-head {
  font-weight: bold; }
  .card .ant-card-head .ant-card-head-title::first-letter {
    text-transform: uppercase; }

.card .ant-card-body {
  padding: 0 24px 24px 24px; }

.card.section .ant-card-head {
  font-size: 1.2rem;
  padding: 0; }

.card.section .ant-card-body {
  padding: 0 0 24px 0; }

.card.card-virtualized {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .card.card-virtualized .ant-card-head {
    flex: 1 1 auto; }
  .card.card-virtualized .ant-card-body {
    height: 100%;
    flex: 1 1 auto;
    padding: 1px 0; }
    .card.card-virtualized .ant-card-body .np-list {
      height: 100%; }

.datepicker-edit-mode {
  width: 100%; }
  .datepicker-edit-mode .ant-picker {
    padding: 4px 4px 4px 11px;
    width: 100%; }
  .datepicker-edit-mode.large .ant-picker {
    padding: 7px 6px 7px 11px; }
  .datepicker-edit-mode.small .ant-picker {
    padding: 0 4px 0 7px; }

.empty-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.filters {
  display: flex;
  flex-flow: row wrap; }
  .filters .filter {
    padding: 0 1rem 1rem 0; }

.first-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .first-loading__logo {
    max-width: 500px; }

.form .ant-form-item-explain::first-letter {
  text-transform: uppercase; }

.form-edit-trigger {
  cursor: pointer; }
  .form-edit-trigger.active {
    color: var(--primary-color); }

.form-editable-title {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: center; }
  .form-editable-title .form-editable-title__text {
    font-size: 1.2rem; }
  .form-editable-title .form-editable-title__edit {
    line-height: 0;
    font-size: .8rem; }

.icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  user-select: none; }
  .icon.large {
    width: 1.5em;
    height: 1.5em; }
  .icon.extra-large {
    width: 2em;
    height: 2em; }

.np-input.np-input-edit-mode .hide-arrows input::-webkit-outer-spin-button,
.np-input.np-input-edit-mode .hide-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.np-input.np-input-edit-mode .hide-arrows input[type='number'] {
  -moz-appearance: textfield; }

.np-input.np-input-edit-mode .ant-input-affix-wrapper {
  padding: 4px 4px 4px 11px; }

.np-input.np-input-edit-mode.large .ant-input-affix-wrapper-lg {
  padding: 7px 6px 7px 11px; }

.np-input.np-input-edit-mode.small .ant-input-affix-wrapper-sm {
  padding: 0 4px 0 7px; }

.np-input.np-input-edit-mode .input-actions {
  padding-left: 8px; }

.np-input.np-input-edit-mode.full-width {
  width: 100%; }

.np-input .input-actions {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center; }
  .np-input .input-actions .input-actions__edit {
    cursor: pointer;
    line-height: 0; }
  .np-input .input-actions .input-actions__edit {
    display: none;
    line-height: 0; }
    .np-input .input-actions .input-actions__edit .input-actions__edit-icon {
      font-size: 16px;
      color: var(--dark-light-color); }
      .np-input .input-actions .input-actions__edit .input-actions__edit-icon:hover {
        color: var(--info-color); }

.np-input .input-suffix-wrapper {
  display: inline-flex;
  flex-wrap: nowrap; }

.input-view-mode {
  display: inline-flex;
  flex-wrap: nowrap;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 1.5;
  position: relative; }
  .input-view-mode.full-width {
    width: 100%; }
  .input-view-mode .input-info .input-info__value-empty {
    font-size: inherit;
    font-style: italic; }
  .input-view-mode .input-info .input-info__value-prefix {
    margin-right: 4px; }
  .input-view-mode .input-info .input-info__value-suffix {
    margin-left: 8px; }
  .input-view-mode .input-actions {
    line-height: 0;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translate(-50%, -50%); }
    .input-view-mode .input-actions .input-actions__edit-icon {
      font-size: 15px; }
  .input-view-mode.fixed-action-trigger .input-actions .input-actions__edit {
    display: block; }
  .input-view-mode.on-click-action-trigger {
    cursor: pointer; }
    .input-view-mode.on-click-action-trigger:hover {
      color: var(--info-color); }
    .input-view-mode.on-click-action-trigger .input-actions .input-actions__edit .input-actions__edit-icon {
      color: var(--info-color); }
  .input-view-mode.on-hover-action-trigger .input-actions .input-actions__edit {
    display: none; }
  .input-view-mode.on-hover-action-trigger:hover .input-actions .input-actions__edit {
    display: block; }
  .input-view-mode.large {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.5;
    margin: 5px 0; }
    .input-view-mode.large .input-actions .input-actions__edit-icon {
      font-size: 18px; }
  .input-view-mode.small {
    padding: 1px 8px;
    font-size: 14px;
    line-height: 1.5;
    margin: 5px 0; }

.input-label-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto; }
  .input-label-container .input-label-container-item {
    align-self: flex-end; }

.input-label {
  font-size: 14px;
  line-height: 1.5;
  margin: 5px 0; }
  .input-label.bold-label {
    font-weight: bold; }
  .input-label.large {
    font-size: 16px; }
  .input-label.small {
    font-size: 14px; }
  .input-label.left {
    margin: 5px 0;
    padding: 5px 4px; }
    .input-label.left.large {
      padding: 8px 4px; }
    .input-label.left.small {
      padding: 1px 4px; }
  .input-label.responsive {
    margin: 5px 0;
    padding: 5px 4px; }
    @media (max-width: 599px) {
      .input-label.responsive {
        margin: 0;
        padding: 0; } }
    .input-label.responsive.large {
      padding: 8px 4px; }
      @media (max-width: 599px) {
        .input-label.responsive.large {
          padding: 0; } }
    .input-label.responsive.small {
      padding: 1px 4px; }
      @media (max-width: 599px) {
        .input-label.responsive.small {
          padding: 0; } }

.layout.main {
  min-height: 100vh; }
  .layout.main .menu-sider .ant-layout-sider-children {
    position: fixed;
    width: 200px; }
  .layout.main .menu-sider.collapsed .ant-layout-sider-children {
    width: 80px; }
  .layout.main .menu-sider.collapsed.smart-devices .ant-layout-sider-children {
    width: 0px; }
  .layout.main .content {
    padding: 1rem; }
    .layout.main .content.basic {
      padding: 0;
      background: #fff; }

.layout .header {
  height: 48px;
  display: flex;
  align-items: center; }
  .layout .header.header-simple {
    height: auto;
    padding: 1rem;
    background-color: #fff; }
    .layout .header.header-simple .header-simple-content {
      max-width: 850px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .layout .header.header-simple .header-simple-content .logo-wrapper {
        width: 150px; }
  .layout .header .logo-wrapper {
    width: 120px; }
  .layout .header .menu-trigger-mobile {
    padding: 0 0.3rem;
    line-height: 0; }

.list-filters {
  display: flex;
  justify-content: flex-end; }
  .list-filters .filter-btn-trigger-wrapper {
    padding: .5rem; }
    .list-filters .filter-btn-trigger-wrapper .filter-btn-trigger {
      transition: 0.3s ease-in-out;
      color: var(--dark-light-color);
      font-size: 0.5rem;
      cursor: pointer; }
      .list-filters .filter-btn-trigger-wrapper .filter-btn-trigger .filter-text {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold; }
      .list-filters .filter-btn-trigger-wrapper .filter-btn-trigger:hover {
        color: var(--dark-medium-color); }

.list-filters__filters .list-filters__filters-item {
  margin-bottom: 1rem; }

.np-order-by-icons {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer; }
  .np-order-by-icons.light {
    color: #fff; }
    .np-order-by-icons.light .np-order-by-icons-wrapper__asc,
    .np-order-by-icons.light .np-order-by-icons-wrapper__desc {
      opacity: 0.6; }
      .np-order-by-icons.light .np-order-by-icons-wrapper__asc.active,
      .np-order-by-icons.light .np-order-by-icons-wrapper__desc.active {
        opacity: 1; }
  .np-order-by-icons.dark .np-order-by-icons-wrapper__asc,
  .np-order-by-icons.dark .np-order-by-icons-wrapper__desc {
    color: var(--dark-light-color); }
    .np-order-by-icons.dark .np-order-by-icons-wrapper__asc.active,
    .np-order-by-icons.dark .np-order-by-icons-wrapper__desc.active {
      color: var(--info-color); }
  .np-order-by-icons .np-order-by-icons-wrapper__asc,
  .np-order-by-icons .np-order-by-icons-wrapper__desc {
    line-height: 0; }
  .np-order-by-icons .np-order-by-icons-wrapper__desc {
    margin-top: -7px; }
  .np-order-by-icons .np-order-by-icons-wrapper__asc {
    margin-bottom: -7px; }

.np-list {
  position: relative; }
  .np-list .np-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .np-list .np-list-wrapper .np-list-row-header {
      flex: 1 1 auto;
      width: 100%; }
    .np-list .np-list-wrapper .np-list-data {
      flex: 1 1 auto;
      height: 100%;
      min-height: 50px;
      margin-bottom: 0.9rem; }
    .np-list .np-list-wrapper .np-list-filters {
      flex: 1 1 auto;
      width: 100%; }
    .np-list .np-list-wrapper .np-list-header {
      flex: 1 1 auto;
      width: 100%; }
    .np-list .np-list-wrapper .np-list-footer {
      flex: 1 1 auto;
      width: 100%; }
    .np-list .np-list-wrapper .np-list-item {
      display: flex;
      align-items: center;
      min-width: 45px;
      width: 100%; }
      .np-list .np-list-wrapper .np-list-item.selectItem, .np-list .np-list-wrapper .np-list-item.checkbox {
        cursor: pointer;
        width: auto; }
    .np-list .np-list-wrapper .np-list-row-header {
      position: sticky;
      background-color: var(--bg-color);
      top: 0;
      z-index: 5; }
      .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items {
        display: grid;
        width: 100%; }
        .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items.virtualized {
          width: calc(100% - 15px); }
        .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item {
          padding: 1rem;
          width: 100%;
          background-color: var(--bg-color);
          display: inline-flex;
          align-items: center;
          user-select: none; }
          .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item:hover {
            background: #e4e4e4; }
          .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item.with-order-by {
            cursor: pointer; }
          .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item .header-item-label {
            font-weight: bold; }
          .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item.checkbox {
            justify-content: center; }
          .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .np-list-item.header-item.disabled {
            background: rgba(252, 62, 129, 0.1); }
        .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items .header-item-scrollbar {
          width: 100%;
          height: 100%;
          background-color: var(--bg-color);
          user-select: none; }
      .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-bulk-actions {
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 1px solid #f0f0f0; }
        .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-bulk-actions .np-list-row-header-bulk-actions-selected-items {
          font-size: 1rem;
          padding: 1rem;
          color: var(--dark-medium-color); }
        .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-bulk-actions .np-list-row-header-bulk-actions-item {
          padding: 1rem; }
    .np-list .np-list-wrapper .np-list-item-content-row {
      transition: 0.3s ease-in-out;
      display: grid; }
      .np-list .np-list-wrapper .np-list-item-content-row.default {
        border-bottom: 1px solid #f0f0f0; }
      .np-list .np-list-wrapper .np-list-item-content-row.card {
        margin-bottom: 5px;
        box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        border-bottom: none; }
      .np-list .np-list-wrapper .np-list-item-content-row.checkable {
        cursor: pointer; }
      .np-list .np-list-wrapper .np-list-item-content-row.disabled {
        background: rgba(252, 62, 129, 0.1); }
      .np-list .np-list-wrapper .np-list-item-content-row:hover {
        box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.2); }
      .np-list .np-list-wrapper .np-list-item-content-row .np-list-item {
        width: 100%;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
        .np-list .np-list-wrapper .np-list-item-content-row .np-list-item.content-item {
          padding: 1rem; }
        .np-list .np-list-wrapper .np-list-item-content-row .np-list-item.disabled {
          background: var(--disabled-color); }
        .np-list .np-list-wrapper .np-list-item-content-row .np-list-item.empty-cell {
          background-color: var(--empty-color); }
  .np-list .np-list-virtualized__item.card {
    padding: 0 0.5rem; }
    .np-list .np-list-virtualized__item.card .np-list-item-content-row {
      margin: 5px 0; }
  .np-list .np-list-add-new {
    position: absolute;
    right: -5px;
    bottom: -15px; }
    .np-list .np-list-add-new .np-list-add-new__icon-wrapper {
      background-color: var(--bg-color);
      color: var(--primary-color);
      border-radius: 4px;
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1); }
  .np-list .empty-wrapper {
    padding-top: 1rem;
    min-height: 150px; }
  .np-list.striped .np-list-item-content-row:nth-of-type(odd) {
    background-color: #f8f8f8; }

@media only screen and (max-width: 960px) {
  .np-list .np-list-wrapper .np-list-row-header .np-list-row-header-items {
    display: none; }
  .np-list .np-list-wrapper .np-list-item-content-row .np-list-item.content-item.checkbox {
    justify-content: flex-end; } }

.ReactVirtualized__List:focus {
  outline: none; }

.logo-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 80vh;
  width: 100%; }
  .logo-loading img.logo-loading__logo {
    width: 100%;
    height: 100%;
    max-width: 500px; }

.modal .ant-modal-footer .ant-btn {
  text-transform: capitalize; }

.popup-confirm .ant-popover-message-title {
  padding-left: 0; }

.popup-confirm .popup-confirm__title-wrapper .popup-confirm__title {
  display: flex;
  align-items: center; }
  .popup-confirm .popup-confirm__title-wrapper .popup-confirm__title .popup-confirm__title__icon {
    line-height: 0; }
  .popup-confirm .popup-confirm__title-wrapper .popup-confirm__title .popup-confirm__title__text {
    font-size: 1.1rem;
    padding-left: 0.5rem; }

.popup-confirm .popup-confirm__title-wrapper .popup-confirm__important-message {
  color: var(--primary-color); }

.result-message.success .ant-result-icon > .anticon {
  color: var(--primary-color); }

.result-message .ant-result-title::first-letter,
.result-message .ant-result-subtitle::first-letter {
  text-transform: uppercase; }

.select-edit-mode {
  width: 100%; }
  .select-edit-mode .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 31px; }
  .select-edit-mode.large .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px; }
  .select-edit-mode.small .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 23px; }
  .select-edit-mode .anticon-loading {
    color: var(--primary-color); }
  .select-edit-mode .value-selected-disabled {
    opacity: 0.5; }

.word-break .ant-select-item-option-content {
  white-space: normal;
  text-overflow: unset;
  word-break: break-word; }
  .word-break .ant-select-item-option-content::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--dark-light-color);
    position: absolute;
    left: 0;
    bottom: 0; }

.table .table-head {
  border-radius: 4px; }
  .table .table-head .table-head-row .table-head-col.cell {
    background-color: var(--bg-color);
    padding: 0.5rem;
    text-transform: capitalize;
    position: relative;
    width: 90px; }
  .table .table-head .table-head-row .table-head-col .cell-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%; }
    .table .table-head .table-head-row .table-head-col .cell-title-wrapper .cell-title {
      width: 100%;
      line-height: 1.4rem; }
    .table .table-head .table-head-row .table-head-col .cell-title-wrapper .cell-subtitle {
      width: 100%;
      color: var(--dark-light-color);
      font-size: 0.5rem;
      line-height: 0;
      text-transform: uppercase; }

.table .table-body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }

span.data-grid-container,
span.data-grid-container:focus {
  outline: none; }

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%; }

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease; }

.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #ddd;
  padding: 0; }

.data-grid-container .data-grid .cell:not(.editing),
.data-grid-container .data-grid .cell.editing .data-editor {
  padding: 0 0.5rem; }

.data-grid-container .data-grid .cell.selected {
  border: 1px double var(--primary-color);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(252, 62, 129, 0.2); }

.data-grid-container .data-grid .cell.read-only:not(.delete),
.table-head .table-head-row .table-head-col.cell.read-only:not(.delete) {
  background: var(--dark-light-color-transparent);
  text-align: center; }
  .data-grid-container .data-grid .cell.read-only:not(.delete).read-only,
  .table-head .table-head-row .table-head-col.cell.read-only:not(.delete).read-only {
    background: var(--disabled-color); }

.data-grid-container .data-grid .cell.read-only {
  color: var(--dark-medium-color); }

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden; }

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid var(--primary-color);
  text-align: right;
  width: 100%;
  height: 100%;
  background: none;
  display: block; }

.data-grid-container .data-grid .cell {
  vertical-align: bottom; }

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal; }

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible; }

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden; }

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
  display: block; }

.text-ellipsable {
  width: 100%; }
  .text-ellipsable .text-ellipsable__text {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: inherit;
    position: relative; }
    .text-ellipsable .text-ellipsable__text.with-colon:after {
      content: ':'; }
    .text-ellipsable .text-ellipsable__text.with-colon .text-overflowed:after {
      content: ':';
      position: absolute;
      right: 0;
      top: 0; }

.title.left {
  text-align: left; }

.title.center {
  text-align: center; }

.title.right {
  text-align: right; }

.tooltip.uppercase {
  text-transform: uppercase; }

.tooltip.no-padding .ant-tooltip-content .ant-tooltip-inner {
  padding: 0; }

.tooltip.basic .ant-tooltip-content .ant-tooltip-inner,
.tooltip.basic .ant-tooltip-content .ant-tooltip-arrow-content {
  background: #fff;
  color: var(--dark-color); }

.tooltip.default .ant-tooltip-content .ant-tooltip-inner,
.tooltip.default .ant-tooltip-content .ant-tooltip-arrow-content {
  background: var(--primary-color); }

.tooltip.neutral .ant-tooltip-content .ant-tooltip-inner,
.tooltip.neutral .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.pending .ant-tooltip-content .ant-tooltip-inner,
.tooltip.pending .ant-tooltip-content .ant-tooltip-arrow-content {
  background: rgba(0, 0, 0, 0.8); }

.tooltip.success .ant-tooltip-content .ant-tooltip-inner,
.tooltip.success .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.active .ant-tooltip-content .ant-tooltip-inner,
.tooltip.active .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.positive .ant-tooltip-content .ant-tooltip-inner,
.tooltip.positive .ant-tooltip-content .ant-tooltip-arrow-content {
  background: var(--confirm-color); }

.tooltip.warning .ant-tooltip-content .ant-tooltip-inner,
.tooltip.warning .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.activeWithWarnings .ant-tooltip-content .ant-tooltip-inner,
.tooltip.activeWithWarnings .ant-tooltip-content .ant-tooltip-arrow-content {
  background: var(--alert-color); }

.tooltip.error .ant-tooltip-content .ant-tooltip-inner,
.tooltip.error .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.inactive .ant-tooltip-content .ant-tooltip-inner,
.tooltip.inactive .ant-tooltip-content .ant-tooltip-arrow-content, .tooltip.negative .ant-tooltip-content .ant-tooltip-inner,
.tooltip.negative .ant-tooltip-content .ant-tooltip-arrow-content {
  background: var(--cancel-color); }

.sellers {
  max-width: 1200px; }

html,
body {
  height: 100%;
  font-family: var(--default-font-family); }

body {
  background-color: var(--bg-color);
  color: var(--dark-color);
  font-size: 14px;
  font-family: 'Raleway', sans-serif; }

a {
  color: inherit;
  text-decoration: none; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline; }

h1 {
  font-size: 2.25rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.25rem; }

h5 {
  font-size: 1rem; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

.lowercase {
  text-transform: lowercase; }

.first-letter::first-letter {
  text-transform: uppercase; }

.first-letter-child span::first-letter {
  text-transform: uppercase; }

img {
  max-width: 100%; }

.flex-center {
  display: flex;
  justify-content: center; }

.np-list .delete-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--dark-medium-color); }
  .np-list .delete-item:hover {
    color: var(--dark-color); }

button.clean-button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.default-font {
  font-family: var(--default-font-family); }
